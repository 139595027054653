<template>
  <v-theme-provider light>
    <v-form ref="form">
      <div>
        <BannerAccepted v-if="isAcceptedProp && !isPageLoading" :timeout="infinityTimeoutProp" :value="true" shaped color="primary" />
      </div>
      <v-overlay :value="isPageLoading">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="12">
          <v-snackbar v-model="showGenericFailure" :timeout="infinityTimeoutProp">
            <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
              <span class="centered">
                <span>That's unexpected! {{ genericFailureErrorMessage }}</span>
                <v-spacer />
                <v-btn
                  @click="
                    showGenericFailure = false
                    isPageLoading = false
                  "
                  text
                  style="display: flex; justify-content: flex-end"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </span>
            </v-container>
          </v-snackbar>
        </v-col>
      </v-row>
      <base-section class="text-center" space="86">
        <base-section-heading title="Approvals" color="accent"><v-icon left>mdi-web</v-icon>{{ networkname }}</base-section-heading>
        <v-responsive class="d-flex align-center" height="100%" width="100%">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12">
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" clearable label="Filter by" single-line hide-details></v-text-field>
                  </v-card-title>
                  <v-data-table :loading="isTableLoading" :headers="headers" :items="professionalsList" :items-per-page="-1" :search="search" class="elevation-1 row-pointer" @click:row="handleRowClick">
                    <template v-slot:item.sales="{ item }">
                      <v-chip :color="getSalesColor(item.sales)" dark>
                        {{ item.sales }}
                      </v-chip>
                    </template>
                    <template v-slot:item.zip="{ item }">
                      <v-chip :color="getZipColor()" label dark>
                        {{ item.zip }}
                      </v-chip>
                    </template>
                    <template v-slot:item.registrationstage="{ item }"> <v-icon :color="stageColor(item.registrationstage)" right>mdi-circle-medium</v-icon> {{ stageName(item.registrationstage) }} </template>
                    <template v-slot:no-results>
                      <v-alert :value="true" type="info">We could not find anything with "{{ search }}" in your network. </v-alert>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-footer :fixed="true">
              <v-row justify="center" align="center" class="footer">
                <v-col cols="12" sm="12">
                  <v-snackbar loading v-model="showSavedProgress" :timeout="longTimeoutProp">
                    Looking for applicants…
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  </v-snackbar>
                  <v-alert v-model="showSavedFailure" :timeout="infinityTimeoutProp" dense dark class="align-top" type="info" prominent>
                    <div class="text-center mt-2">
                      <span class="body-2">{{ savedFailureErrorMessage }}</span>
                    </div>
                    <div class="text-center mt-1">
                      <v-btn x-small color="white" class="black--text" @click="showSavedFailure = false">OK</v-btn>
                    </div>
                  </v-alert>
                </v-col>
              </v-row>
            </v-footer>
          </v-container>
        </v-responsive>
      </base-section>
    </v-form>
  </v-theme-provider>
</template>

<script>
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { RegistrationStage, AlertTimeout } from '@/RegistrationStage.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  components: {
    BannerAccepted: () => import('./BannerAccepted.vue')
  },

  name: 'ApprovalSection',
  data: () => ({
    isPageLoading: true,
    isTableLoading: false,
    professionalsList: [],
    networkname: '',
    search: '',

    showSavedFailure: false,
    showSavedProgress: false,
    showSavedSuccess: false,
    savedFailureErrorMessage: '',
    showGenericFailure: false,
    genericFailureErrorMessage: '',

    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'fullname'
      },
      {
        text: 'Annual Clients Served',
        value: 'sales'
      },
      {
        text: 'Profession',
        value: 'profession'
      },
      {
        text: 'Zip',
        value: 'zip'
      },
      {
        text: 'City',
        value: 'city'
      },
      {
        text: 'State',
        value: 'state'
      },
      {
        text: 'Status',
        value: 'registrationstage'
      }
    ],
    registrationStage: RegistrationStage.Disabled
  }),

  mounted() {
    setTimeout(this.fetchRegistrationStage, this.nullTimeoutProp)
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    },

    longTimeoutProp() {
      return AlertTimeout.long
    },

    infinityTimeoutProp() {
      return AlertTimeout.infinity
    },

    isAcceptedProp() {
      return this.registrationStage === RegistrationStage.Accepted
    }
  },

  methods: {
    handleRowClick(person) {
      this.$router.push({
        name: 'approveapplicationroute',
        params: {
          person
        }
      })
    },

    fetchRegistrationStage() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '👩‍✈️ ApprovalsListSection.fetchRegistrationStage', // log hint
        endpoints.myprofileEndpoint, // endpoint
        environmentVariables.MY_PROFILE_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.genericFailureErrorMessage = ''
          this.isPageLoading = true
          this.showGenericFailure = false

          return true
        },

        // onSuccess
        (data) => {
          this.isPageLoading = false
          this.registrationStage = data.profile.registrationstage
          this.networkname = data.profile.networkname
          this.fetchPendingMembersList()
        },

        // All sort of errors
        (error, errorDescription) => {
          this.isPageLoading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorDescription
          }
        }
      )
    },

    getSalesColor(sales) {
      // TODO: to be rewritten!
      if (sales < 15) return '#A3EBB1'
      else if (sales > 15 && sales < 40) return '#21B6A8'
      else return '#7A89C5'
    },

    getZipColor() {
      // to be rwritten
      return 'gray'
    },

    stageColor(registrationstage) {
      switch (registrationstage) {
        case RegistrationStage.Submitted:
          return 'primary'
        case RegistrationStage.Rejected:
          return 'error'
        case RegistrationStage.Accepted:
          return 'success'
        default:
          return 'black'
      }
    },

    stageName(registrationstage) {
      switch (registrationstage) {
        case RegistrationStage.Submitted:
          return 'Pending Review'
        case RegistrationStage.Rejected:
          return 'Rejected'
        case RegistrationStage.Accepted:
          return 'Approved'
        default:
          return 'Invalid'
      }
    },

    fetchPendingMembersList() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '👬 ApprovalsListSection.fetchPendingMembersList', // log hint
        endpoints.myApprovalsEndpoint, // endpoint
        environmentVariables.MY_APPROVALS_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.isTableLoading = true
          this.professionalsList = []
          this.savedFailureErrorMessage = ''
          this.showHeader = false
          this.showSavedFailure = false
          this.showSavedProgress = true

          return true
        },

        // onSuccess
        (data) => {
          this.formisdirty = false
          this.isTableLoading = false
          this.professionalsList = data.list ? data.list : []
          this.showHeader = true
          this.showSavedProgress = false
        },

        // All sort of errors
        (error, errorDescription) => {
          this.isTableLoading = false
          this.showSavedProgress = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showSavedFailure = true
            var errorMessage = error.message
            switch (error.message) {
              case 'ownerid':
                errorMessage = "There isn't any application for you to review"
                break
              default:
                errorMessage = errorDescription
            }
            this.savedFailureErrorMessage = errorMessage
          }
        }
      )
    },

    showLoggedOutBanner() {
      // MustLoginSection should already have a modal
    }
  }
}
</script>

<style>
.row-pointer:hover {
  cursor: pointer;
}
</style>
